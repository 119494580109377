import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _80d5d74c = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _4620cdaf = () => interopDefault(import('../pages/classes/index.vue' /* webpackChunkName: "pages/classes/index" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _1b64f649 = () => interopDefault(import('../pages/download-app/index.vue' /* webpackChunkName: "pages/download-app/index" */))
const _c459a238 = () => interopDefault(import('../pages/free-starter-kit/index.vue' /* webpackChunkName: "pages/free-starter-kit/index" */))
const _b2856564 = () => interopDefault(import('../pages/freebies/index.vue' /* webpackChunkName: "pages/freebies/index" */))
const _8f26073a = () => interopDefault(import('../pages/gre/index.vue' /* webpackChunkName: "pages/gre/index" */))
const _6792e545 = () => interopDefault(import('../pages/gre-live-classes/index.vue' /* webpackChunkName: "pages/gre-live-classes/index" */))
const _22f10bdb = () => interopDefault(import('../pages/latex-testing.vue' /* webpackChunkName: "pages/latex-testing" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _4b90b77c = () => interopDefault(import('../pages/on-demand/index.vue' /* webpackChunkName: "pages/on-demand/index" */))
const _e5a7e686 = () => interopDefault(import('../pages/on-demand-v2/index.vue' /* webpackChunkName: "pages/on-demand-v2/index" */))
const _e1fa0b8e = () => interopDefault(import('../pages/performance/index.vue' /* webpackChunkName: "pages/performance/index" */))
const _71071aa0 = () => interopDefault(import('../pages/premium/index.vue' /* webpackChunkName: "pages/premium/index" */))
const _640c6856 = () => interopDefault(import('../pages/priority-planner/index.vue' /* webpackChunkName: "pages/priority-planner/index" */))
const _7de68bc4 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _14144b6c = () => interopDefault(import('../pages/referral/index.vue' /* webpackChunkName: "pages/referral/index" */))
const _a76fb7de = () => interopDefault(import('../pages/vocab-connect/index.vue' /* webpackChunkName: "pages/vocab-connect/index" */))
const _7c59cdd5 = () => interopDefault(import('../pages/priority-planner/plan.vue' /* webpackChunkName: "pages/priority-planner/plan" */))
const _544944cf = () => interopDefault(import('../pages/vocab-connect/practice/index.vue' /* webpackChunkName: "pages/vocab-connect/practice/index" */))
const _eecd2070 = () => interopDefault(import('../pages/vocab-connect/report/index.vue' /* webpackChunkName: "pages/vocab-connect/report/index" */))
const _5fcf40fa = () => interopDefault(import('../pages/premium/pay/_planSlug.vue' /* webpackChunkName: "pages/premium/pay/_planSlug" */))
const _929349ca = () => interopDefault(import('../pages/gre/_slug.vue' /* webpackChunkName: "pages/gre/_slug" */))
const _244c8184 = () => interopDefault(import('../pages/questions/_testSectionOrder.vue' /* webpackChunkName: "pages/questions/_testSectionOrder" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blogs",
    component: _80d5d74c,
    name: "blogs"
  }, {
    path: "/classes",
    component: _4620cdaf,
    name: "classes"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/download-app",
    component: _1b64f649,
    name: "download-app"
  }, {
    path: "/free-starter-kit",
    component: _c459a238,
    name: "free-starter-kit"
  }, {
    path: "/freebies",
    component: _b2856564,
    name: "freebies"
  }, {
    path: "/gre",
    component: _8f26073a,
    name: "gre"
  }, {
    path: "/gre-live-classes",
    component: _6792e545,
    name: "gre-live-classes"
  }, {
    path: "/latex-testing",
    component: _22f10bdb,
    name: "latex-testing"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/on-demand",
    component: _4b90b77c,
    name: "on-demand"
  }, {
    path: "/on-demand-v2",
    component: _e5a7e686,
    name: "on-demand-v2"
  }, {
    path: "/performance",
    component: _e1fa0b8e,
    name: "performance"
  }, {
    path: "/premium",
    component: _71071aa0,
    name: "premium"
  }, {
    path: "/priority-planner",
    component: _640c6856,
    name: "priority-planner"
  }, {
    path: "/privacy-policy",
    component: _7de68bc4,
    name: "privacy-policy"
  }, {
    path: "/profile",
    component: _4bb7511c,
    name: "profile"
  }, {
    path: "/referral",
    component: _14144b6c,
    name: "referral"
  }, {
    path: "/vocab-connect",
    component: _a76fb7de,
    name: "vocab-connect"
  }, {
    path: "/priority-planner/plan",
    component: _7c59cdd5,
    name: "priority-planner-plan"
  }, {
    path: "/vocab-connect/practice",
    component: _544944cf,
    name: "vocab-connect-practice"
  }, {
    path: "/vocab-connect/report",
    component: _eecd2070,
    name: "vocab-connect-report"
  }, {
    path: "/premium/pay/:planSlug?",
    component: _5fcf40fa,
    name: "premium-pay-planSlug"
  }, {
    path: "/gre/:slug",
    component: _929349ca,
    name: "gre-slug"
  }, {
    path: "/questions/:testSectionOrder?",
    component: _244c8184,
    name: "questions-testSectionOrder"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
